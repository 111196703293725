.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding-left: 10%;
  padding-right: 10%;
}

textarea {
  margin-bottom: 50px;
  border-radius: 20px;
  padding: 10px;
  max-width: 800px;
}
.job-description {
  width: 80vw;
  height: 20vh;
}

.prompt {
  width: 80vw;
  height: 10vh;
}

.result {
  width: 80vw;
  height: 50vh;
}

.titles {
  padding-left: 10%;
  padding-right: 10%;
}

.splash-small {
  font-size: calc(5px + 2vmin);
}

a {
  color: #61dafb;
}

@keyframes slideMe{
0%{
  transform: skewX(53deg) translateX(500px);
}
100%{
  transform: skew(0deg);
}
}

@keyframes slideMeOut{
0%{
  transform: skewX(-53deg) translateX(-500px);
}
100%{
  transform: skew(0deg);
}
}

.button {
  width: 250px;
  height: 60px;
  font-family: 'Roboto', sans-serif;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  font-weight: 550;
  color: #000;
  background-color: #fff;
  border: none;
  border-radius: 45px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
}

.button.cancel-btn:hover {
  background-color: #F3270B;
  box-shadow: 0px 15px 20px rgba(243, 42, 11, 0.4);
  color: #fff;
  transform: translateY(-7px);
}

.button.accept-btn:hover {
  background-color: #0B91F3;
  box-shadow: 0px 15px 20px rgba(11, 113, 243, 0.4);
  color: #fff;
  transform: translateY(-7px);
}

.job-description-label, .prompt-label {
  font-size: 12px;
  float: left;
  margin-top: 10px;
  padding-left: 10%;
}

/* Upload box css */
.upload-box {
    border-radius: 20px;
    margin-bottom: 16px;
    padding: 40px;
    max-width: 800px;
}

#file-upload-label {
    cursor: pointer;
    font-size: 15px;
    color: #1e8ae9;
}

.upload-content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #cacdd1;
}

.drop-main-text {
    display: inline-block;
    font-size: 15px;
    line-height: 20px;
    font-weight: 400;
}

.drop-minor-text {
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
}